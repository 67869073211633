import styled from 'styled-components';

const DegenesisEvent = styled.div`
  background: linear-gradient(286.02deg, rgba(255, 255, 255, 0.2) 1.93%, rgba(255, 255, 255, 0) 29.63%), linear-gradient(118.08deg, rgba(0, 0, 0, 0.24) 19.85%, rgba(0, 0, 0, 0) 69.28%), #FFD800;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1;
  font-size: 20px;
  padding-left: 2.4px;
`;

const TokeMak = styled.div`
  font-size: 46.4px;
  line-height: 0.99;
  padding-left: 1px;
`;

const ProgressUpper = styled.div`
  padding-left: 11.2px;
  letter-spacing: 1.1px;
  line-height: 1;
`;

const ProgressBarWrapper = styled.div`
  padding-left: 7.2px;
  padding-top: 0.8px;
`;

const ProgressLower = styled.div`
  letter-spacing: 0px;
  padding-top: 1.6px;
`;

const ProgressLowerSmall = styled.div`
  font-size: 8px;
  letter-spacing: 0.1px;
  padding-right: 1.6px;
`;

const LockUpper = styled.div`
  font-size: 10px;
  letter-spacing: 0.7px;
  line-height: 1;
  margin-top: 3px;
  margin-bottom: 1.8px;
`;

const UnlockWrapper = styled.div`
  background-color: rgba(1, 198, 206, .3);
  border-top: 1px solid #132222;
  border-bottom: 1px solid #132222;
  height: 17px;
  width: 49px;
`;

const LockWrapper = styled.div`
  background-color: rgba(1, 198, 206, 1);
  border-top: 1px solid #132222;
  border-bottom: 1px solid #132222;
  height: 17px;
  width: 49px;
`;

const ProgressUpperB = styled.div`
  padding-left: 3.2px;
  letter-spacing: 1.1px;
  line-height: 1;
`;

const ProgressBarWrapperB = styled.div`
  padding-top: 0.8px;
`;

const ProgressLowerB = styled.div`
  letter-spacing: 0px;
  padding-top: 1.6px;
`;

const SwapUpper = styled.div`
  font-size: 10px;
  padding-left: 3.2px;
  letter-spacing: 0.7px;
  line-height: 1;
  margin-top: 3px;
  margin-bottom: 1.8px;
  text-align: center;
`;

const UnswapWrapper = styled.div`
  background-color: rgba(1, 198, 206, .3);
  border-top: 1px solid #132222;
  border-bottom: 1px solid #132222;
  height: 17px;
  width: 48px;
`;

const SwapWrapper = styled.div`
  background-color: rgba(1, 198, 206, 1);
  border-top: 1px solid #132222;
  border-bottom: 1px solid #132222;
  height: 17px;
  width: 48px;
`;

const ProgressLowerSmallB = styled.div`
  font-size: 8px;
  letter-spacing: 0.1px;
  padding-right: 4px;
`;

interface ISocialConnect {
  flag: boolean;
}

const SocialConnect = styled.div<ISocialConnect>`
  padding-top: ${(props) => (props.flag ? '0px' : '32px')};
  padding-right: 16px;
`;

const ConnectWrapper = styled.div`
  background-color: rgba(101, 101, 101, 0.1);
  border-radius: 32px;
  height: 40px;
`;

const WalletWrapper = styled.div`
  height: 105%;
  border-radius: 32px;
  border: 1px solid #009BA1;
  transition: background .15s ease-in-out;

  &:hover {
    background: #132222;
    border: 1px solid #01C6CE;
    color: #01C6CE;
  }
`;

const BanxaWrapper = styled.div`
  height: 105%;
  border-radius: 32px;
  border: 1px solid #009BA1;
  transition: background .15s ease-in-out;

  &:hover {
    background: #132222;
    border: 1px solid #01C6CE;
    color: #01C6CE;
  }
`;

const SocialLinkIcon = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 24px;
  background-color: black;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ProgressContainer = styled.div`
  padding-top: 39px;
`;

export {
  DegenesisEvent,
  TokeMak,
  ProgressUpper,
  ProgressBarWrapper,
  ProgressLower,
  ProgressLowerSmall,
  LockUpper,
  UnlockWrapper,
  LockWrapper,
  ProgressUpperB,
  ProgressBarWrapperB,
  ProgressLowerB,
  SwapUpper,
  UnswapWrapper,
  SwapWrapper,
  ProgressLowerSmallB,
  SocialConnect,
  ConnectWrapper,
  WalletWrapper,
  BanxaWrapper,
  SocialLinkIcon,
  ProgressContainer,
};
