export const TwitterLink = 'https://twitter.com/PerionDAO';
export const MediumLink = 'https://medium.com/@PerionDAO/perion-perc-ido-event-9c131460b5c7';
export const DiscordLink = 'https://discord.gg/perion';
export const TwitterTokenomicsLink =
  'https://twitter.com/PerionDAO';
export const MediumTokenomicsLink =
  'https://medium.com/@PerionDAO/perion-perc-ido-event-9c131460b5c7';
export const MediumTokemakDegenesis =
  'https://medium.com/@PerionDAO/perion-perc-ido-event-9c131460b5c7';
export const TokemakRadio = 'https://www.tokemak.radio/';
export const TokemakXYZ = 'https://ido.perion.gg';
export const DiscordIconLink = DiscordLink;
