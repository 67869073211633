import { useState } from 'react';
import styled from 'styled-components';
import { BiRightArrowAlt } from 'react-icons/bi';

import { DiscordLink, MediumTokemakDegenesis } from '../constants/links';
import TokoIcon from '../assets/img/logo-perion.svg';
import TimesThin from '../assets/img/times-thin.svg';

const SocialLink = styled.span`
  color: #048a8f;
  text-decoration: underline;
`;

interface WelcomeToke2Props {
  width?: number;
  height?: number;
  onCloseClick?: (flag: boolean) => void;
}

export default function WelcomeToke2({
  width,
  height,
  onCloseClick,
}: WelcomeToke2Props) {
  const [visibleFlag, setVisibleFlag] = useState<boolean>(true);

  function setMainStyle() {
    let styles = {
      borderRadius: '5px',
      border: '1px solid #132222',
      marginLeft: '15px',
      marginTop: '-7px',
    };
    if (width && height) {
      const firstStyle = {
        width: '' + width + 'px',
        height: '' + height + 'px',
      };
      styles = Object.assign(styles, firstStyle);
    }
    return styles;
  }

  function setLogoContainerStyle() {
    let styles = {
      width: '137px',
      height: '137px',
      left: '-68.5px',
      top: '-70.5px',
      backgroundColor: '#000000',
      paddingLeft: '16px',
      paddingRight: '16px',
      paddingTop: '16px',
      paddingBottom: '16px',
    };
    return styles;
  }

  function setLogoStyle() {
    let styles = {
      borderRadius: '50%',
      // border: '2px solid #048A8F',
      width: '100%',
      height: '100%',
      paddingTop: '21px',
      paddingLeft: '20px',
    };
    return styles;
  }

  function setTitleStyle() {
    let styles = {
      paddingLeft: '98px',
      paddingTop: '40px',
      fontSize: '36px',
      lineHeight: '1',
    };
    return styles;
  }

  function setDescriptionStyle() {
    let styles = {
      paddingLeft: '48px',
      paddingRight: '48px',
      // marginBottom: '65px',
      fontSize: '20px',
      lineHeight: '1.2',
      paddingTop: '30px',
    };
    return styles;
  }

  function setButtonsStyle() {
    let styles = {
      height: '130px',
    };
    return styles;
  }

  function onClose() {
    setVisibleFlag(false);
    if (onCloseClick) onCloseClick(true);
  }

  function setContinueStyle() {
    let styles = {
      borderBottom: '1px solid #132222',
      height: '16px',
      marginRight: '50px',
      fontSize: '20px',
      marginTop: '-25px',
    };
    return styles;
  }

  function setRightStyle() {
    let styles = {
      marginRight: '-6px',
      marginTop: '-2px',
    };
    return styles;
  }

  function setArrowStyle() {
    let styles = {
      marginTop: '-2px',
      marginLeft: '3px',
      marginRight: '20px',
    };
    return styles;
  }

  function setLineStyle() {
    let styles = {
      marginTop: '4px',
      marginLeft: '0px',
    };
    return styles;
  }

  function setLine2Style() {
    let styles = {
      marginTop: '30px',
      marginLeft: '0px',
    };
    return styles;
  }

  function setCloseStyle() {
    let styles = {
      border: '1px solid #132222',
      right: '26px',
      top: '23px',
      width: '36px',
      height: '36px',
    };
    return styles;
  }

  function setReadStyle() {
    let styles = {
      fontSize: '18.2px',
      letterSpacing: '0.1px',
      paddingLeft: '51px',
      marginTop: '-22px',
    };
    return styles;
  }

  function setJoinStyle() {
    let styles = {
      fontSize: '18.2px',
      letterSpacing: '0.2px',
      paddingLeft: '51px',
      marginTop: '-22px',
    };
    return styles;
  }

  return (
    <div>
      {visibleFlag && (
        <div className="relative flex flex-col" style={setMainStyle()}>
          <div className="absolute px-3 py-3" style={setLogoContainerStyle()}>
            <div className="px-3 py-3 flex" style={setLogoStyle()}>
              <img src={TokoIcon} width="62px" alt="Token Icon" />
            </div>
          </div>
          <div
            className="absolute text-white cursor-pointer"
            style={setCloseStyle()}
            onClick={(e) => onClose()}
          >
            <img src={TimesThin} alt="" />
          </div>
          <div className="flex-1 flex flex-col">
            <div
              className="uppercase text-2xl text-left font-bold"
              style={setTitleStyle()}
            >
              <span className="text-white">THE PERC IDO </span>
              <span className="text-yellow">Tl;dr</span>
            </div>
            <div
              className="text-white text-md text-left"
              style={setDescriptionStyle()}
            >
              <div className="flex flex-row" style={setLineStyle()}>
                <BiRightArrowAlt
                  className="align-middle text-yellow flex-shrink-0"
                  size={40}
                  style={setArrowStyle()}
                />
                The IDO event will allow for commitments over a 7 day period,
                beginning January 21st, followed by a 24 hour last look period
              </div>
              <div className="flex flex-row" style={setLine2Style()}>
                <BiRightArrowAlt
                  className="align-middle text-yellow flex-shrink-0"
                  size={40}
                  style={setArrowStyle()}
                />
                4,000,000 PERC will be available throughout the IDO event, with
                all participants receiving PERC at the same price point of
                between $1.20 and $4.80
              </div>
              <div className="flex flex-row" style={setLine2Style()}>
                <BiRightArrowAlt
                  className="align-middle text-yellow flex-shrink-0"
                  size={40}
                  style={setArrowStyle()}
                />
                The final price per PERC is determined by the total number of
                commitments, capped at $4.80 once commitments reach US $19.2MM
              </div>
              {/* <div className="flex flex-row" style={setLine2Style()}>
                <BiRightArrowAlt
                  className="align-middle text-yellow flex-shrink-0"
                  size={40}
                  style={setArrowStyle()}
                />
                Everyone’s commitments qualify for the same relative split
                between PERC swap and private farming (detailed extensively in
                the Medium article)
              </div> */}
              <div className="flex flex-row" style={setLine2Style()}>
                <BiRightArrowAlt
                  className="align-middle text-yellow flex-shrink-0"
                  size={40}
                  style={setArrowStyle()}
                />
                Once commitments surpass US $19.2MM, up to the US $38.4MM max
                cap, a proportional private farming split is globally applied
              </div>
              <div className="flex flex-row" style={setLine2Style()}>
                <BiRightArrowAlt
                  className="align-middle text-yellow flex-shrink-0"
                  size={40}
                  style={setArrowStyle()}
                />
                All event participants will receive the same proportionate split
                across PERC and the private farming period, with the private
                farming period lasting 2 weeks
              </div>
              <div className="flex flex-row" style={setLine2Style()}>
                <BiRightArrowAlt
                  className="align-middle text-yellow flex-shrink-0"
                  size={40}
                  style={setArrowStyle()}
                />
                Individual wallets will be able to commit a maximum of US
                $100,000 in USDC
              </div>
              <div className="flex flex-row" style={setLine2Style()}>
                <BiRightArrowAlt
                  className="align-middle text-yellow flex-shrink-0"
                  size={40}
                  style={setArrowStyle()}
                />
                SushiSwap PERC/ETH LP staking and single-sided PERC staking will
                be launched after the last look period
              </div>
            </div>
          </div>
          <hr />
          <div
            style={setButtonsStyle()}
            className="flex flex-row items-center justify-between text-white"
          >
            <div style={setJoinStyle()}>
              JOIN OUR{' '}
              <a
                href={DiscordLink}
                target="_blank"
                rel="noreferrer"
                className="text-brightgreen"
              >
                <SocialLink>DISCORD</SocialLink>
              </a>{' '}
              FOR UPDATES
            </div>
            <div style={setReadStyle()}>
              READ OUR{' '}
              <a
                href={MediumTokemakDegenesis}
                target="_blank"
                rel="noreferrer"
                className="text-brightgreen"
              >
                <SocialLink>MEDIUM POST</SocialLink>
              </a>
            </div>
            {/* </div> */}
            <div
              className="text-white flex flex-row items-center cursor-pointer"
              style={setContinueStyle()}
              onClick={(e) => onClose()}
            >
              continue to site
              <BiRightArrowAlt
                className="align-middle"
                size={28}
                style={setRightStyle()}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
